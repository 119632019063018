@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max(100vh, 40rem);
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/images/bg.jpeg);
    background-size: cover;
    background-position: center;
}

.visual-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30rem;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/images/bg.jpeg);
    background-size: cover;
    background-position: center;
}

.active{
    position: relative;
    z-index: 2;
    color: white !important;
    font-weight: 500 !important;
}

.active:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50rem;
    background-color: #2CA8FF;
    z-index: -1;
}

.tariffa {
    padding:1rem;
    border-radius: 0.5rem;
    width:100%;
    color:black;
    border: 1px solid #2CA8FF;
    cursor: pointer;
}

.tariffa:hover{
    background-color: rgba(44, 168, 255, 0.8);
    color:white;
}

.tariffa-attiva{
    background-color: #2CA8FF;
    color:white;
}
